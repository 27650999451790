.TelegramWebAppComponent {
    margin-bottom: 30rem;

    @media (max-width: 768px) {
        min-height: unset;
        margin-bottom: 60rem;
    }
}


.heading {
    font-size: 10rem;
    text-align: center;
    margin-bottom: 8rem;
    color: white;
    font-weight: 500;
    line-height: 90%;
    font-family: "Feature Mono";

    span {
        color: #B168E7;
    }

    @media (max-width: 768px) {
        font-size: 16.4rem;
    }
}

.cards {
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.video {
    flex-shrink: 0;
    width: 53rem;

    @media (max-width: 768px) {
        margin: 0 auto 15rem;
        width: 130rem;
        height: auto;
    }

    img {
        width: 100%;
    }
}

.text {
    margin-left: 5rem;
    padding-top: 2rem;
    width: 90rem;
    @media (max-width: 768px) {
        width: 95%;
    }
    .textHeading {
        display: block;

        @media (max-width: 768px) {
            //display: none;
        }
    }
}

.textHeading {
    margin-bottom: 6rem;
    font-size: 5rem;
    font-weight: bold;
    color: white;
    width: 90%;
    display: none;

    @media (max-width: 768px) {
        text-align: center;
        width: 100%;
        font-size: 7.7rem;
        margin-bottom: 20px;
    }
}

.description {
    color: white;
    font-size: 3.3rem;

    span {
        font-weight: 550;
    }

    @media (max-width: 768px) {
        font-size: 7.3rem;
    }
}

.headLine {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 8rem;

    @media (max-width: 768px) {
        margin-top: 24rem;
        margin-bottom: 0;
    }
}


.tabsWrap {
    position: absolute;
    width: 100%;
    height: 100%;

    span {
        position: absolute;
        display: inline-block;
        padding: 2rem 3rem;
        border-radius: 6.7rem;
        font-size: 3.2rem;
        line-height: 1;

        &:nth-child(1) {
            top: -3rem;
            left: 0;
            background-color: #32FFB9;
            transform: rotate(-8deg);
            animation-duration: 3s;
            animation-name: anim2;
            animation-iteration-count: infinite;
        }

        &:nth-child(3) {
            top: -1rem;
            right: 19.5rem;
            background-color: #32FFB9;
            transform: rotate(-7deg);
            color: white;
            animation-duration: 3s;
            animation-name: anim1;
            animation-iteration-count: infinite;
        }

        &:nth-child(2) {
            top: 9rem;
            right: 0;
            background-color: #B168E7;
            transform: rotate(9deg);
            animation-duration: 3s;
            animation-name: anim3;
            animation-iteration-count: infinite;
        }
        
        @media (max-width: 768px) {
            font-size: 8rem;

            &:nth-child(1) {
                top: -15rem;
                left: -2rem;
            }

            &:nth-child(2) {
                top: -30rem;
            }

            &:nth-child(3) {
                top: -10rem;
            }
        }
        @media (min-width: 768px){
            display: none;
        }
    }
}



@keyframes anim1 {
    0% {
        transform: rotate(-7deg) translate(0,0);
    }
    50% {
        transform: rotate(-7deg) translate(0,10px);
    }
    100% {
        transform: rotate(-7deg) translate(0,0);
    }
}

@keyframes anim2 {
    0% {
        transform: rotate(-8deg) translate(0,10px);
    }
    50% {
        transform: rotate(-10deg) translate(0,0);
    }
    100% {
        transform: rotate(-8deg) translate(0,10px);
    }
}

@keyframes anim3 {
    0% {
        transform: rotate(9deg) translate(0,10px);
    }
    50% {
        transform: rotate(9deg) translate(0,0);
    }
    100% {
        transform: rotate(9deg) translate(0,10px);
    }
}

.gradientTitle {
    font-size: 5.5rem;
    font-weight: 550;
    background-image: linear-gradient(to right, #0CFFAB, #BB5FEA);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    @media (max-width: 768px) {
        font-size: 10rem;
    }
}
.gradientBackground {
    position: relative;
    height: 21.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2.5rem;
    margin-bottom: 2rem;
    backdrop-filter: blur(20px);
    background: rgba(0,0,0,0.4);
    border: 2px solid rgba(255,255,255,0.06);
    z-index: 1;
    @media (max-width: 768px) {
        border-radius: 9rem;
        height: 30.8rem;
    }
}
.gradientBackgroundForText {
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 2.5rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    backdrop-filter: blur(20px);
    background: rgba(0,0,0,0.4);
    border: 2px solid rgba(255,255,255,0.06);
    padding: 6rem 6rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media (max-width: 768px) {
        margin-bottom: 6rem;
        margin-top: 6rem;
        padding: 10rem 10rem;
        gap: 7rem;
        border-radius: 9rem;
    }
}
.fon1 {
    border-radius: 1323.577px;
    opacity: 0.7;
    background: radial-gradient(50% 50% at 50% 50%, #31D79E 0%, rgba(49, 215, 158, 0.00) 100%);
    filter: blur(100px);
    width: 80rem;
    height: 91rem;
    position: absolute;
    top: -45rem;
    right: -15rem;
}

.fon2 {
    border-radius: 1647px;
    opacity: 0.7;
    background: radial-gradient(50% 50% at 50% 50%, #F42BFF 0%, rgba(244, 43, 255, 0.00) 100%);
    filter: blur(50px);
    width: 70rem;
    height: 90rem;
    position: absolute;
    top: -45rem;
    right: -50rem;
}
.right {
    width: 50.5rem;
    position: relative;
}
