._ {
    width: 100%;
    margin-bottom: 30rem;
    position: relative;
}

.fonBlock {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -2;
}

.fon1 {
    border-radius: 1323.577px;
    opacity: 0.3;
    background: radial-gradient(60% 50% at 50% 50%, #31D79E 0%, rgba(49, 215, 158, 0.00) 100%);
    filter: blur(100px);
    width: 200rem;
    height: 161rem;
    position: absolute;
    bottom: -30rem;
    left: -30rem;
    @media (max-width: 768px) {
        display: none;
    }
}

.fon2 {
    border-radius: 1647px;
    opacity: 0.3;
    background: radial-gradient(77.77% 50% at 50% 50%, #F42BFF 0%, rgba(244, 43, 255, 0.00) 100%);
    filter: blur(100px);
    width: 135rem;
    height: 139rem;
    position: absolute;
    top: -10rem;
    right: -13rem;
}

.block{
    backdrop-filter: blur(20px);
    background: rgba(0,0,0,0.4);
    border-radius: 2.5rem;
    border: 4px solid rgba(255,255,255,0.06);

    @media (max-width: 768px) {
        border-radius: 9rem;
    }
}
