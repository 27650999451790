.AdvantagesUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.wrapper {
    height: 80rem;
    width: 100%;
    padding: 0 8.3rem;

    @media (max-width: 768px) {
        height: auto;
        padding: 0 5rem 26rem;
    }
}

.headLine {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    margin-bottom: 8rem;
}

.headLineContainer {

}

.title {
    font-size: 10rem;
    color: white;
    font-weight: 500;
    line-height: 90%;
    font-family: "Feature Mono";

    @media (max-width: 768px) {
        font-size: 16rem;
    }
}

.subTitle {
    font-size: 4rem;
    text-align: right;
    color: white;
    font-weight: 500;
    line-height: 90%;
    font-family: "Feature Mono";

    @media (max-width: 768px) {
        font-size: 7.7rem;
    }
}

.tabsWrap {
    position: absolute;
    width: 100%;
    height: 100%;

    span {
        position: absolute;
        display: inline-block;
        padding: 1rem 3rem;
        border-radius: 6.7rem;
        font-size: 3.2rem;
        line-height: 1;

        &:nth-child(1) {
            top: 0;
            right: 10rem;
            background-color: #B168E7;
            transform: rotate(-7deg);
            color: white;
            animation-duration: 3s;
            animation-name: anim1;
            animation-iteration-count: infinite;
        }
    }

    @media (max-width: 768px) {
        span {
            font-size: 7.7rem;

            &:nth-child(1) {
                top: -13rem;
                right: 110rem;
            }
        }
    }
}

@keyframes anim1 {
    0% {
        transform: rotate(-7deg) translate(0,0);
    }
    50% {
        transform: rotate(-7deg) translate(0,10px);
    }
    100% {
        transform: rotate(-7deg) translate(0,0);
    }
}

.cards {
    display: flex;
    justify-content: space-between;
}

.left {
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    border-radius: 1.8rem;
    background-color: #fff;
    padding: 8rem 5.8rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
        border-radius: 8rem;
        padding: 7.7rem 2rem 12rem;
    }
}

.absence {
    position: relative;
    margin-bottom: 25px;
    font-size: 3.5rem;
    line-height: 5rem;
    color: #1C1C1E;
    font-weight: 600;
    z-index: 2;

    &:before{
        position: absolute;
        content: "";
        display: block;
        width: 20rem;
        height: 5rem;
        background-image: url('../../../static/images/authgone.png');
        background-position: center;
        background-size: contain;
        z-index: -1;
    }
    
    @media (max-width: 768px) {
        font-size: 7.7rem;
        margin-bottom: 15px;
        line-height: 1;
        text-align: center;

        &:before {
            content: none;
        }
    }
}

.row {
    display: flex;

    img {
        width: 26rem;
        margin-right: 3rem;
    }

    p {
        margin-top: 2rem;
        text-align: center;
        font-size: 2.1rem;
        line-height: 2.3rem;
        color: #1C1C1E;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;

        img {
            width: 47rem;
            margin-bottom: 5rem;
        }

        p {
            font-size: 6.3rem;
            line-height: 1;
        }
    }
}

.right {
    width: 63rem;
    height: 40rem;
    background-color: #B168E7;
    border-radius: 2.5rem;
    padding: 5.4rem 8.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h5 {
        font-size: 3.5rem;
        line-height: 4.5rem;
        font-weight: 600;
        color: #F5F5F7;
        margin-bottom: 3rem;
    }

    img {
        width: 37.5rem;
        height: 12rem;
        margin-bottom: 3rem;
    }

    p {
        position: relative;
        width: 46.2rem;
        text-align: center;
        font-size: 2.1rem;
        line-height: 2.5rem;
        color: #F5F5F7;

        &:before {
            display: none;
            content: "";
            position: absolute;
            top: 4.6rem;
            left: 10.3rem;
            width: .8rem;
            height: .8rem;
            background-color: #32FFB9;
            border-radius: 100%;
            @media (max-width: 768px) {
                top: 30.6rem;
                left: 8.3rem;
                width: 1.8rem;
                height: 1.8rem;
            }
        }

        &:after {
            display: none;
            content: "";
            position: absolute;
            top: 4.6rem;
            right: 2.2rem;
            width: .8rem;
            height: .8rem;
            background-color: #32FFB9;
            border-radius: 100%;
            @media (max-width: 768px) {
                top: 37.2rem;
                right: 14.2rem;
                width: 1.8rem;
                height: 1.8rem;
            }
        }

        span {
            background-color: #9543d1;
            padding: 0 .6rem .4rem;
            border-left: 2px solid #32FFB9;
            border-right: 2px solid #32FFB9;
        }
    }

    @media (max-width: 768px) {
        width: calc(50% - 4.5rem / 2);
        border-radius: 9rem;
        padding: 13rem 2.5rem 5rem;
        height: auto;

        h5 {
            font-size: 7.7rem;
            margin-bottom: 7.3rem;
        }

        img {
            width: 100%;
            height: auto;
            margin-bottom: 7rem;
        }

        p {
            font-size: 6.3rem;
            line-height: 1.1;
            width: unset;
        }
    }
}

.bigBottomCard {
    width: 73rem;
    background-image: linear-gradient(to right, #0CFFAB, #BB5FEA);
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 768px) {
        width: calc(50% - 4.5rem / 2);
        border-radius: 9rem;
    }
}

.blackInnerBorder {
    background-color: #1C1C1E;
    width: calc(100% - .4rem);
    height: calc(100% - .4rem);
    border-radius: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        border-radius: 9rem;
    }
}
