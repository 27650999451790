.hero {
    min-height: calc(100vh - 9.5rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        min-height: auto;
    }
}

.heading {
    margin: auto 0 6rem;
    font-size: 10rem;
    text-align: center;
    color: white;
    opacity: 0;
    transform: translateY(-20%);
    font-weight: 500;
    line-height: 90%;
    font-family: "Feature Mono";

    @media (max-width: 768px) {
        font-size: 20rem;
        margin-top: 90.9rem;
        margin-bottom: 18rem;
        opacity: 1;
        transform: translateY(0);
        width: 150rem;
    }
}

.heading span {
    background-image: linear-gradient(to right, #0CFFAB, #F42BFF);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;

    @media (max-width: 768px) {

    }
}

.description {
    width: 110rem;
    font-size: 4rem;
    text-align: center;
    color: white;
    margin-bottom: auto;
    opacity: 0;
    transform: translateY(-20%);

    @media (max-width: 768px) {
        font-size: 8.2rem;
        width: 100%;
        margin-bottom: 109rem;
        opacity: 1;
        transform: translateY(0);
    }
}

.fonBlock {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
}

.fon1 {
    border-radius: 1323.577px;
    opacity: 0.3;
    background: radial-gradient(50% 50% at 50% 50%, #31D79E 0%, rgba(49, 215, 158, 0.00) 100%);
    filter: blur(100px);
    width: 100%;
    height: 100%;
    position: absolute;
    top: -35%;
    right: -20%;
}

.fon2 {
    border-radius: 1647px;
    opacity: 0.3;
    background: radial-gradient(50% 50% at 50% 50%, #F42BFF 0%, rgba(244, 43, 255, 0.00) 100%);
    filter: blur(50px);
    width: 100%;
    height: 100%;
    position: absolute;
    top: -25%;
    right: -55%;
}
