.flip-unit {
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
}

.flip-label {
    margin-bottom: 5px;
    font-size: 14px;
}

.flip-container {
    perspective: 1000px;
}

.flip-card {
    position: relative;
    width: 80px;
    height: 100px;
}

.card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #444;
    color: white;
    line-height: 100px;
    font-size: 48px;
    border-radius: 5px;
    backface-visibility: hidden;
}

/* Передняя сторона – отображает старое значение */
.front {
    z-index: 2;
}

/* Задняя сторона – по умолчанию повернута */
.back {
    transform: rotateX(90deg);
}

/* Класс для запуска анимации переворота */
.animate {
    animation: flip 0.6s forwards;
}

@keyframes flip {
    0% {
        transform: rotateX(90deg);
    }
    50% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(-90deg);
    }
}