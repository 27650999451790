.WorkWithUs {
    margin-bottom: 30rem;
    padding-bottom: 10rem;
}

.heading {
    text-align: center;
    font-size: 10rem;
    color: white;
    margin-bottom: 9rem;
    font-weight: 500;
    line-height: 90%;
    font-family: "Feature Mono";

    @media (max-width: 768px) {
        font-size: 16.4rem;
    }
}

.heading span {
    color: #B168E7;
}
