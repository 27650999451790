* {
    box-sizing: border-box;
}

html {
    font-size: .520883vw;
}

pre,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-size: initial;
    font-weight: normal;
}

input {
    outline: none;
}

button,
input,
optgroup,
select,
textarea {
    padding: 0;
    border: none;
    background-color: transparent;
    outline: none;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

a {
    text-decoration: none;
    color: inherit;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
}

li {
    list-style: none;
}

body {
    overflow-y: scroll;
    background-color: #000;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
}
