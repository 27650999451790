.Advantages {
    display: flex;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98rem;
    margin: 0 auto;
    width: 100%;

    @media (max-width: 768px) {
        height: auto;
        margin-bottom: 40rem;
    }
}

.content {
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    padding: 0 8rem;
    
    @media (max-width: 768px) {
        padding: 0 5rem;
    }
}


.headLine {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    margin-bottom: 8rem;

    @media (max-width: 768px) {
        margin-top: 24rem;
        margin-bottom: 0;
    }
}

.headLineContainer {
    @media (max-width: 768px) {
        margin-bottom: 18rem;
    }
}

.title {
    font-size: 10rem;
    color: white;
    font-weight: 500;
    line-height: 90%;
    font-family: "Feature Mono";
    
    @media (max-width: 768px) {
        font-size: 16.3rem;
    }
}

.subTitle {
    font-size: 4rem;
    text-align: right;
    color: white;
    font-weight: 500;
    line-height: 90%;
    font-family: "Feature Mono";

    @media (max-width: 768px) {
        font-size: 7.7rem;
    }
}

.tabsWrap {
    position: absolute;
    width: 100%;
    height: 100%;

    span {
        position: absolute;
        display: inline-block;
        padding: 1rem 3rem;
        border-radius: 6.7rem;
        font-size: 3.2rem;
        line-height: 1;

        &:nth-child(1) {
            top: -3rem;
            left: 0;
            background-color: #32FFB9;
            transform: rotate(-8deg);
            animation-duration: 3s;
            animation-name: anim2;
            animation-iteration-count: infinite;
        }

        &:nth-child(2) {
            top: -1rem;
            right: 19.5rem;
            background-color: #B168E7;
            transform: rotate(-7deg);
            color: white;
            animation-duration: 3s;
            animation-name: anim1;
            animation-iteration-count: infinite;
        }

        &:nth-child(3) {
            top: 9rem;
            right: 0;
            background-color: #32FFB9;
            transform: rotate(9deg);
            animation-duration: 3s;
            animation-name: anim3;
            animation-iteration-count: infinite;
        }
        
        @media (max-width: 768px) {
            font-size: 8rem;

            &:nth-child(1) {
                top: -15rem;
                left: -2rem;
            }

            &:nth-child(2) {
                top: -30rem;
            }

            &:nth-child(3) {
                top: -10rem;
            }
        }
    }
}

@keyframes anim1 {
    0% {
        transform: rotate(-7deg) translate(0,0);
    }
    50% {
        transform: rotate(-7deg) translate(0,10px);
    }
    100% {
        transform: rotate(-7deg) translate(0,0);
    }
}

@keyframes anim2 {
    0% {
        transform: rotate(-8deg) translate(0,10px);
    }
    50% {
        transform: rotate(-10deg) translate(0,0);
    }
    100% {
        transform: rotate(-8deg) translate(0,10px);
    }
}

@keyframes anim3 {
    0% {
        transform: rotate(9deg) translate(0,10px);
    }
    50% {
        transform: rotate(9deg) translate(0,0);
    }
    100% {
        transform: rotate(9deg) translate(0,10px);
    }
}

.cards {
    display: flex;
}

.analysis {
    display: flex;
    flex-direction: column;
    width: 45.1rem;
    background-color: #B168E7;
    border-radius: 2.5rem;
    padding: 5.5rem 4.4rem 9rem;

    @media (max-width: 768px) {
        display: none;
    }

    h5 {
        text-align: center;
        font-size: 3.5rem;
        font-weight: 600;
        color: white;
        margin-bottom: 5rem;
    }

    p {
        font-size: 2.3rem;
        text-align: center;
        color: white;
    }
}

.analImg {
    width: 26.8rem;
    margin: 0 auto 5rem;
}

.additional {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 2.5rem;
    background-color: #F5F5F7;
    padding: 4rem 7rem 5.5rem;

    h5 {
        position: relative;
        font-size: 3.5rem;
        line-height: 4.6rem;
        font-weight: 600;
        color: #1C1C1E;
        text-align: center;
        margin-bottom: 1.2rem;

        &:before {
            content: "";
            position: absolute;
            left: 0.5rem;
            width: 3rem;
            height: 4.1rem;
            background-image: url('../../../static/images/Union.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @media (max-width: 768px) {
                width: 12rem;
                height: 15.1rem;
            }
        }

        &:after {
            content: "";
            position: absolute;
            right: .5rem;
            width: 3rem;
            height: 4.1rem;
            background-image: url('../../../static/images/Union.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @media (max-width: 768px) {
                top: 2px;
                width: 12rem;
                height: 15.1rem;
            }
        }
    }

    p {
        font-size: 2.3rem;
        line-height: 2.5rem;
        text-align: center;
        color: #1C1C1E;
        width: 60rem;
        margin: 0 auto;
        font-weight: 400;
    }
    
    @media (max-width: 768px) {
        border-radius: 9rem;
        padding: 14rem 10rem 10rem;

        h5 {
            font-size: 7.7rem;
            line-height: 1;
            margin-bottom: 7rem;
        }

        p {
            width: unset;
            font-size: 7rem;
            line-height: 1;

            span {
                display: none;
            }
        }
    }
}


.left {
    flex-grow: 1;
    margin-right: 2rem;

    @media (max-width: 768px) {
        margin-right: 0;
    }

    .analysis {
        display: none;

        @media (max-width: 768px) {
            display: flex;
            width: unset;
            margin-top: 4rem;
            border-radius: 9rem;
            padding-bottom: 8rem;
            margin-bottom: 7rem;

            h5 {
                font-size: 7.7rem;
            }

            img {
                width: 78.6rem;
            }

            p {
                font-size: 6.4rem;
            }
        }
    }
}

.originalBgc {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, #0CFFAB, #BB5FEA);
    border-radius: 2.5rem;
    margin-right: 2rem;
    width: 45.1rem;

    @media (max-width: 768px) {
        width: 80rem;
        border-radius: 9rem;
        flex-shrink: 0;
    }
}

.originalContent {
    position: relative;
    width: calc(100% - .8rem);
    height: calc(100% - .8rem);
    background-color: #1C1C1E;
    border-radius: 2rem;
    padding-top: 5.5rem;

    @media (max-width: 768px) {
        width: calc(100% - .9rem);
        height: calc(100% - .9rem);
        border-radius: 8.1rem;
        padding: 7rem .2rem 3rem;
    }

    h5 {
        position: relative;
        color: white;
        font-weight: 600;
        font-size: 3.5rem;
        text-align: center;
        margin-bottom: 3rem;

        &:before {
            content: "";
            position: absolute;
            top: -.4rem;
            left: 5rem;
            width: 3rem;
            height: 5rem;
            background-image: url('../../../static/images/original_l.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &:after {
            content: "";
            position: absolute;
            top: -.4rem;
            right: 4.5rem;
            width: 3rem;
            height: 5rem;
            background-image: url('../../../static/images/original_r.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        
        @media (max-width: 768px) {
            font-size: 7.7rem;
            margin-bottom: 6rem;
            &:after,
            &:before {
                content: none;
            }
        }
    }

    p {
        width: 40rem;
        color: white;
        text-align: center;
        font-size: 2.3rem;
        line-height: 2.5rem;
        margin: 0 2.5rem 6.4rem;

        span {
            background-color: #27A278;
            padding: .15rem .5rem;
            color: #1C1C1E;
        }

        @media (max-width: 768px) {
            font-size: 6rem;
            line-height: 1.1;
            width: unset;
        }
    }
}

.economy {
    width: 45.1rem;
    background-color: #FAF3A6;
    border-radius: 2.5rem;
    padding: 3rem 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    h5 {
        text-align: center;
        color: #1C1C1E;
        font-size: 3.5rem;
        line-height: 4.6rem;
        font-weight: 600;
        margin-bottom: .6rem;
    }

    img {
        width: 15.9rem;
        margin-bottom: 1rem;
    }

    p {
        font-size: 2.3rem;
        line-height: 2.5rem;
        text-align: center;
    }

    @media (max-width: 768px) {
        width: unset;
        border-radius: 9.1rem;
        padding: 6rem 3rem 5rem;

        h5 {
            font-size: 7rem;
            line-height: 1;
        }

        p {
            font-size: 6rem;
            line-height: 1;
        }

        img {
            width: 31.3rem;
        }
    }
}

.topLine {
    display: flex;
    margin-bottom: 2rem;
}
